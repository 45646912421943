import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Hey 👋, I'm Josh"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" keywords={[`josh amore`, `joshamore`, `blog`]} />

        <p>I'm a software engineer from Melbourne, Australia.</p>

        <p>
          In my spare time I create things like{" "}
          <a href="https://clothingtracker.xyz">ClothingTracker.xyz</a>,{" "}
          <a href="https://jobmate.work">JobMate</a>,{" "}
          <a href="https://topsubreddit.joshamore.com">TopSubreddits</a>,{" "}
          <a href="https://mytinnies.com">MyTinnies</a>, and grow purple beans.
        </p>
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
